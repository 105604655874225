.slate-code_block code[class*="language-"], .slate-code_block pre[class*="language-"] {
  color: #383a42;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  tab-size: 2;
  -webkit-hyphens: none;
  hyphens: none;
  direction: ltr;
  background: #fafafa;
  font-family: Fira Code, Fira Mono, Menlo, Consolas, DejaVu Sans Mono, monospace;
  line-height: 1.5;
}

.slate-code_block code[class*="language-"]::selection {
  color: inherit;
  background: #e5e5e6;
}

.slate-code_block code[class*="language-"] ::selection {
  color: inherit;
  background: #e5e5e6;
}

.slate-code_block pre[class*="language-"] ::selection {
  color: inherit;
  background: #e5e5e6;
}

.slate-code_block pre[class*="language-"] {
  border-radius: .3em;
  margin: .5em 0;
  padding: 1em;
  overflow: auto;
}

.slate-code_block :not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .3em;
  padding: .2em .3em;
}

.token.comment, .token.prolog, .token.cdata {
  color: #a0a1a7;
}

.token.doctype, .token.punctuation, .token.entity {
  color: #383a42;
}

.token.attr-name, .token.class-name, .token.boolean, .token.constant, .token.number, .token.atrule {
  color: #b76b01;
}

.token.keyword {
  color: #a626a4;
}

.token.property, .token.tag, .token.symbol, .token.deleted, .token.important {
  color: #e45649;
}

.token.selector, .token.string, .token.char, .token.builtin, .token.inserted, .token.regex, .token.attr-value, .token.attr-value > .token.punctuation {
  color: #50a14f;
}

.token.variable, .token.operator, .token.function {
  color: #4078f2;
}

.token.url {
  color: #0184bc;
}

.token.attr-value > .token.punctuation.attr-equals, .token.special-attr > .token.attr-value > .token.value.css {
  color: #383a42;
}

.language-css .token.selector {
  color: #e45649;
}

.language-css .token.property {
  color: #383a42;
}

.language-css .token.function, .language-css .token.url > .token.function {
  color: #0184bc;
}

.language-css .token.url > .token.string.url {
  color: #50a14f;
}

.language-css .token.important, .language-css .token.atrule .token.rule, .language-javascript .token.operator {
  color: #a626a4;
}

.language-javascript .token.template-string > .token.interpolation > .token.interpolation-punctuation.punctuation {
  color: #ca1243;
}

.language-json .token.operator {
  color: #383a42;
}

.language-json .token.null.keyword {
  color: #b76b01;
}

.language-markdown .token.url, .language-markdown .token.url > .token.operator, .language-markdown .token.url-reference.url > .token.string {
  color: #383a42;
}

.language-markdown .token.url > .token.content {
  color: #4078f2;
}

.language-markdown .token.url > .token.url, .language-markdown .token.url-reference.url {
  color: #0184bc;
}

.language-markdown .token.blockquote.punctuation, .language-markdown .token.hr.punctuation {
  color: #a0a1a7;
  font-style: italic;
}

.language-markdown .token.code-snippet {
  color: #50a14f;
}

.language-markdown .token.bold .token.content {
  color: #b76b01;
}

.language-markdown .token.italic .token.content {
  color: #a626a4;
}

.language-markdown .token.strike .token.content, .language-markdown .token.strike .token.punctuation, .language-markdown .token.list.punctuation, .language-markdown .token.title.important > .token.punctuation {
  color: #e45649;
}

.token.bold {
  font-weight: bold;
}

.token.comment, .token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.token.namespace {
  opacity: .8;
}

.token.token.tab:not(:empty):before, .token.token.cr:before, .token.token.lf:before, .token.token.space:before {
  color: rgba(56, 58, 66, .2);
}

div.code-toolbar > .toolbar.toolbar > .toolbar-item {
  margin-right: .4em;
}

div.code-toolbar > .toolbar.toolbar > .toolbar-item > button, div.code-toolbar > .toolbar.toolbar > .toolbar-item > a, div.code-toolbar > .toolbar.toolbar > .toolbar-item > span {
  color: #696c77;
  background: #e5e5e6;
  border-radius: .3em;
  padding: .1em .4em;
}

div.code-toolbar > .toolbar.toolbar > .toolbar-item > button:hover, div.code-toolbar > .toolbar.toolbar > .toolbar-item > button:focus, div.code-toolbar > .toolbar.toolbar > .toolbar-item > a:hover, div.code-toolbar > .toolbar.toolbar > .toolbar-item > a:focus, div.code-toolbar > .toolbar.toolbar > .toolbar-item > span:hover, div.code-toolbar > .toolbar.toolbar > .toolbar-item > span:focus {
  color: #383a42;
  background: #c6c7c7;
}

.line-highlight.line-highlight {
  background: rgba(56, 58, 66, .05);
}

.line-highlight.line-highlight:before, .line-highlight.line-highlight[data-end]:after {
  color: #383a42;
  background: #e5e5e6;
  border-radius: .3em;
  padding: .1em .6em;
  box-shadow: 0 2px rgba(0, 0, 0, .2);
}

pre[id].linkable-line-numbers.linkable-line-numbers span.line-numbers-rows > span:hover:before {
  background-color: rgba(56, 58, 66, .05);
}

.line-numbers.line-numbers .line-numbers-rows, .command-line .command-line-prompt {
  border-right-color: rgba(56, 58, 66, .2);
}

.line-numbers .line-numbers-rows > span:before, .command-line .command-line-prompt > span:before {
  color: #9d9d9f;
}

.rainbow-braces .token.token.punctuation.brace-level-1, .rainbow-braces .token.token.punctuation.brace-level-5, .rainbow-braces .token.token.punctuation.brace-level-9 {
  color: #e45649;
}

.rainbow-braces .token.token.punctuation.brace-level-2, .rainbow-braces .token.token.punctuation.brace-level-6, .rainbow-braces .token.token.punctuation.brace-level-10 {
  color: #50a14f;
}

.rainbow-braces .token.token.punctuation.brace-level-3, .rainbow-braces .token.token.punctuation.brace-level-7, .rainbow-braces .token.token.punctuation.brace-level-11 {
  color: #4078f2;
}

.rainbow-braces .token.token.punctuation.brace-level-4, .rainbow-braces .token.token.punctuation.brace-level-8, .rainbow-braces .token.token.punctuation.brace-level-12 {
  color: #a626a4;
}

pre.diff-highlight > code .token.token.deleted:not(.prefix), pre > code.diff-highlight .token.token.deleted:not(.prefix) {
  background-color: rgba(255, 82, 102, .15);
}

pre.diff-highlight > code .token.token.deleted:not(.prefix)::selection {
  background-color: rgba(251, 86, 105, .25);
}

pre.diff-highlight > code .token.token.deleted:not(.prefix) ::selection {
  background-color: rgba(251, 86, 105, .25);
}

pre > code.diff-highlight .token.token.deleted:not(.prefix)::selection {
  background-color: rgba(251, 86, 105, .25);
}

pre > code.diff-highlight .token.token.deleted:not(.prefix) ::selection {
  background-color: rgba(251, 86, 105, .25);
}

pre.diff-highlight > code .token.token.inserted:not(.prefix), pre > code.diff-highlight .token.token.inserted:not(.prefix) {
  background-color: rgba(26, 255, 91, .15);
}

pre.diff-highlight > code .token.token.inserted:not(.prefix)::selection {
  background-color: rgba(56, 224, 98, .25);
}

pre.diff-highlight > code .token.token.inserted:not(.prefix) ::selection {
  background-color: rgba(56, 224, 98, .25);
}

pre > code.diff-highlight .token.token.inserted:not(.prefix)::selection {
  background-color: rgba(56, 224, 98, .25);
}

pre > code.diff-highlight .token.token.inserted:not(.prefix) ::selection {
  background-color: rgba(56, 224, 98, .25);
}

.prism-previewer.prism-previewer:before, .prism-previewer-gradient.prism-previewer-gradient div {
  border-color: hsl(0, 0, 95%);
}

.prism-previewer-color.prism-previewer-color:before, .prism-previewer-gradient.prism-previewer-gradient div, .prism-previewer-easing.prism-previewer-easing:before {
  border-radius: .3em;
}

.prism-previewer.prism-previewer:after {
  border-top-color: hsl(0, 0, 95%);
}

.prism-previewer-flipped.prism-previewer-flipped.after {
  border-bottom-color: hsl(0, 0, 95%);
}

.prism-previewer-angle.prism-previewer-angle:before, .prism-previewer-time.prism-previewer-time:before, .prism-previewer-easing.prism-previewer-easing {
  background: #fff;
}

.prism-previewer-angle.prism-previewer-angle circle, .prism-previewer-time.prism-previewer-time circle {
  stroke: #383a42;
  stroke-opacity: 1;
}

.prism-previewer-easing.prism-previewer-easing circle, .prism-previewer-easing.prism-previewer-easing path, .prism-previewer-easing.prism-previewer-easing line {
  stroke: #383a42;
}

.prism-previewer-easing.prism-previewer-easing circle {
  fill: rgba(0, 0, 0, 0);
}

.react-tagsinput {
  color: #495057;
  cursor: text;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, .12);
  border-radius: 3px;
  width: 100%;
  padding: .2rem .5rem .1rem;
  font-size: .9375rem;
  line-height: 1.6;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

.react-tagsinput__icon-container {
  padding: 0 6px 0 0;
  display: inline-block;
  transform: translateY(-1px);
}

.react-tagsinput__icon {
  cursor: default;
  vertical-align: middle;
  font-size: 1rem;
}

.react-tagsinput__content {
  width: calc(100% - 2rem);
  display: inline-block;
}

.react-tagsinput__clear-button {
  cursor: pointer;
  position: absolute;
  top: calc(50% - .7rem);
  left: calc(100% - 1.5rem);
}

.react-tagsinput::-ms-expand {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}

.react-tagsinput--empty {
  padding: .2rem .5rem;
}

.react-tagsinput--focused {
  color: #495057;
  background-color: #fff;
  border-color: #1991eb;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(70, 127, 207, .25);
}

.react-tagsinput-input {
  color: #495057;
  background: none;
  border: 0;
  outline: none;
  width: 240px;
  margin: 0;
  padding: 5px 0;
  font-size: 13px;
  font-weight: 400;
}

.react-tagsinput-input::placeholder {
  color: #adb5bd;
  opacity: 1;
}

.react-tagsinput-tag {
  color: #24587e;
  background-color: #daeefc;
  border: 1px solid #cbe7fb;
  border-radius: 2px;
  margin: 0 .15rem .15rem 0;
  padding: 3px 5px;
  font-size: 13px;
  font-weight: 400;
  display: inline-block;
}

.react-tagsinput-tag + .react-tagsinput-input {
  margin-left: .15rem;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag span:before {
  content: " ×";
}

.react-tagsinput-invalid-tag {
  color: #6b1110;
  background-color: #f5d2d2;
  border: 1px solid #f1c1c0;
}

.react-autosuggest__container {
  margin-left: .25rem;
  display: inline-block;
  position: relative;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  z-index: 70;
  background-color: #fff;
  border: 1px solid rgba(0, 40, 100, .12);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  width: 100%;
  display: block;
  position: absolute;
  top: 36px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: .25rem 1rem;
}

.react-autosuggest__suggestion--highlighted {
  color: #16181b;
  background-color: #e9ecef;
}

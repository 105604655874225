.container {
  background: #fff;
  border: 1px solid rgba(0, 40, 100, .12);
  flex-direction: column;
  display: flex;
  position: relative;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, .15);
}

.lineStyleContainer {
  border-top: 1px solid rgba(0, 40, 100, .12);
  margin: 0 1rem 1rem;
  padding-top: 1rem;
}

.colorPickerContainer {
  margin: 1rem 1rem 1rem .5rem;
  display: flex;
}

@media (max-width: 992px) {
  .colorPickerContainer {
    margin: 1rem;
    display: block;
  }
}

.color {
  border-radius: 3px;
  width: 7.625rem;
  height: 7.625rem;
  margin-bottom: .25rem;
  position: relative;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, .15);
}

@media (max-width: 992px) {
  .color {
    width: 100%;
    height: 4rem;
    margin-top: .5rem;
  }
}

.colorContainer {
  flex-direction: column;
  margin-right: 2rem;
  display: flex;
}

.row {
  width: 16rem;
}

.cross {
  position: absolute;
  top: .25rem;
  right: .25rem;
}

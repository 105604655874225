.arrow {
  color: #ccc;
  display: inline-block;
}

.arrow.visible {
  color: #000;
}

.sortingArrows {
  flex-flow: column;
  margin-left: .25rem;
  display: flex;
}

.sortingArrows:hover .arrow {
  color: #456;
  cursor: pointer;
}

@import "~tippy.js/dist/tippy.css";
@import "~react-toastify/dist/ReactToastify.css";
@import "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700";

.page {
  padding: 0 1rem;
}

.invalid-feedback {
  display: block;
}

.dimmer.active .dimmer-content {
  opacity: .4;
}

a.icon.active {
  color: #495057 !important;
}

.btn-link:hover, .btn-link:focus {
  text-decoration: none;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-BoldItalic.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-BoldItalic.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-ThinItalic.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-ThinItalic.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-MediumItalic.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-MediumItalic.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-BlackItalic.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-BlackItalic.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-RegularItalic.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-RegularItalic.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-Black.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-Black.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-Bold.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-Bold.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-Regular.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-Regular.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-LightItalic.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-LightItalic.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-Light.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-Light.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-Medium.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-Medium.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-Thin.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-Thin.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-Light.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-Light.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-Bold.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-Bold.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-MediumItalic.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-MediumItalic.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-Regular.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-Regular.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-SemiBold.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-SemiBold.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-SemiBoldItalic.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-SemiBoldItalic.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-ExtraBold.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-ExtraBold.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-Black.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-Black.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-BlackItalic.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-BlackItalic.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-Medium.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-Medium.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-LightItalic.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-LightItalic.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-Italic.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-Italic.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-ExtraBoldItalic.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-ExtraBoldItalic.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-ExtraBoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-BoldItalic.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-BoldItalic.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

:root {
  --toastify-z-index: 999999;
}

body.dark {
  background-color: #121212;
}

a {
  color: #3771c8;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

a:hover {
  color: #3771c8;
  text-decoration: underline;
}

svg {
  vertical-align: baseline;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-strike {
  text-decoration: line-through;
}

.no-pointer-events {
  pointer-events: none;
}

.block-highlight {
  animation: 2.5s background-fade;
}

.tippy-popper {
  opacity: .9;
  text-align: center;
}

.svg-graph {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif !important;
}

.pre {
  white-space: pre-wrap;
}

.nf-highlight {
  color: #de3644;
}

.flex-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

button:disabled {
  cursor: default;
}

.graph-dropdown-menu {
  width: 7rem;
  min-width: 7rem;
}

.export-only {
  visibility: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

@keyframes ld-breath {
  0% {
    transform: scale(.86);
  }

  50% {
    transform: scale(1.06);
  }

  100% {
    transform: scale(.86);
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%)scale(.86);
  }

  50% {
    transform: translate(-50%, -50%)scale(1.06);
  }

  100% {
    transform: translate(-50%, -50%)scale(.86);
  }
}

@keyframes background-fade {
  from {
    background: #de3644;
  }

  to {
    background: none;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

#ck-pause-wall > * {
  z-index: 999999 !important;
}

:root {
  --background: 0 0% 100%;
  --foreground: 222.2 84% 4.9%;
  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 222.2 84% 4.9%;
  --card: 0 0% 100%;
  --card-foreground: 222.2 84% 4.9%;
  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;
  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;
  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 40% 98%;
  --ring: 215 20.2% 65.1%;
  --radius: .5rem;
}

.dark {
  --background: 222.2 84% 4.9%;
  --foreground: 210 40% 98%;
  --muted: 217.2 32.6% 17.5%;
  --muted-foreground: 215 20.2% 65.1%;
  --popover: 222.2 84% 4.9%;
  --popover-foreground: 210 40% 98%;
  --card: 222.2 84% 4.9%;
  --card-foreground: 210 40% 98%;
  --border: 217.2 32.6% 17.5%;
  --input: 217.2 32.6% 17.5%;
  --primary: 210 40% 98%;
  --primary-foreground: 222.2 47.4% 11.2%;
  --secondary: 217.2 32.6% 17.5%;
  --secondary-foreground: 210 40% 98%;
  --accent: 217.2 32.6% 17.5%;
  --accent-foreground: 210 40% 98%;
  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 0 85.7% 97.3%;
  --ring: 217.2 32.6% 17.5%;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1400px;
  }
}

.app-root .sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.app-root .pointer-events-none {
  pointer-events: none;
}

.app-root .pointer-events-auto {
  pointer-events: auto;
}

.app-root .visible {
  visibility: visible;
}

.app-root .collapse {
  visibility: collapse;
}

.app-root .static {
  position: static;
}

.app-root .fixed {
  position: fixed;
}

.app-root .absolute {
  position: absolute;
}

.app-root .relative {
  position: relative;
}

.app-root .sticky {
  position: sticky;
}

.app-root .inset-0 {
  inset: 0;
}

.app-root .inset-x-0 {
  left: 0;
  right: 0;
}

.app-root .-bottom-3 {
  bottom: -.75rem;
}

.app-root .-bottom-px {
  bottom: -1px;
}

.app-root .-left-3 {
  left: -.75rem;
}

.app-root .-right-0 {
  right: 0;
}

.app-root .-right-0\.5 {
  right: -.125rem;
}

.app-root .-right-3 {
  right: -.75rem;
}

.app-root .-top-0 {
  top: 0;
}

.app-root .-top-0\.5 {
  top: -.125rem;
}

.app-root .-top-3 {
  top: -.75rem;
}

.app-root .-top-px {
  top: -1px;
}

.app-root .bottom-\[-5px\] {
  bottom: -5px;
}

.app-root .left-0 {
  left: 0;
}

.app-root .left-2 {
  left: .5rem;
}

.app-root .left-\[-1\.5px\] {
  left: -1.5px;
}

.app-root .left-\[-5px\] {
  left: -5px;
}

.app-root .left-\[50\%\] {
  left: 50%;
}

.app-root .right-0 {
  right: 0;
}

.app-root .right-2 {
  right: .5rem;
}

.app-root .right-4 {
  right: 1rem;
}

.app-root .right-\[-1\.5px\] {
  right: -1.5px;
}

.app-root .right-\[-5px\] {
  right: -5px;
}

.app-root .top-0 {
  top: 0;
}

.app-root .top-1\/2 {
  top: 50%;
}

.app-root .top-2 {
  top: .5rem;
}

.app-root .top-4 {
  top: 1rem;
}

.app-root .top-\[50\%\] {
  top: 50%;
}

.app-root .isolate {
  isolation: isolate;
}

.app-root .z-10 {
  z-index: 10;
}

.app-root .z-20 {
  z-index: 20;
}

.app-root .z-30 {
  z-index: 30;
}

.app-root .z-40 {
  z-index: 40;
}

.app-root .z-50 {
  z-index: 50;
}

.app-root .z-\[100\] {
  z-index: 100;
}

.app-root .z-\[1\] {
  z-index: 1;
}

.app-root .z-\[500\] {
  z-index: 500;
}

.app-root .col-auto {
  grid-column: auto;
}

.app-root .float-right {
  float: right;
}

.app-root .m-0 {
  margin: 0;
}

.app-root .m-7 {
  margin: 1.75rem;
}

.app-root .-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.app-root .mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.app-root .mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.app-root .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.app-root .my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.app-root .my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.app-root .my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.app-root .-ml-3 {
  margin-left: -.75rem;
}

.app-root .-mr-3 {
  margin-right: -.75rem;
}

.app-root .mb-1 {
  margin-bottom: .25rem;
}

.app-root .mb-2 {
  margin-bottom: .5rem;
}

.app-root .mb-2\.5 {
  margin-bottom: .625rem;
}

.app-root .mb-4 {
  margin-bottom: 1rem;
}

.app-root .mb-6 {
  margin-bottom: 1.5rem;
}

.app-root .mb-px {
  margin-bottom: 1px;
}

.app-root .ml-0 {
  margin-left: 0;
}

.app-root .ml-0\.5 {
  margin-left: .125rem;
}

.app-root .ml-1 {
  margin-left: .25rem;
}

.app-root .ml-2 {
  margin-left: .5rem;
}

.app-root .ml-auto {
  margin-left: auto;
}

.app-root .ml-px {
  margin-left: 1px;
}

.app-root .mr-0 {
  margin-right: 0;
}

.app-root .mr-1 {
  margin-right: .25rem;
}

.app-root .mr-1\.5 {
  margin-right: .375rem;
}

.app-root .mr-2 {
  margin-right: .5rem;
}

.app-root .mr-4 {
  margin-right: 1rem;
}

.app-root .mr-auto {
  margin-right: auto;
}

.app-root .mt-0 {
  margin-top: 0;
}

.app-root .mt-1 {
  margin-top: .25rem;
}

.app-root .mt-2 {
  margin-top: .5rem;
}

.app-root .mt-4 {
  margin-top: 1rem;
}

.app-root .mt-5 {
  margin-top: 1.25rem;
}

.app-root .mt-\[0\.5em\] {
  margin-top: .5em;
}

.app-root .mt-\[0\.75em\] {
  margin-top: .75em;
}

.app-root .mt-\[1em\] {
  margin-top: 1em;
}

.app-root .box-border {
  box-sizing: border-box;
}

.app-root .box-content {
  box-sizing: content-box;
}

.app-root .block {
  display: block;
}

.app-root .inline-block {
  display: inline-block;
}

.app-root .inline {
  display: inline;
}

.app-root .flex {
  display: flex;
}

.app-root .inline-flex {
  display: inline-flex;
}

.app-root .table {
  display: table;
}

.app-root .grid {
  display: grid;
}

.app-root .contents {
  display: contents;
}

.app-root .list-item {
  display: list-item;
}

.app-root .hidden {
  display: none;
}

.app-root .aspect-square {
  aspect-ratio: 1;
}

.app-root .h-0 {
  height: 0;
}

.app-root .h-0\.5 {
  height: .125rem;
}

.app-root .h-10 {
  height: 2.5rem;
}

.app-root .h-11 {
  height: 2.75rem;
}

.app-root .h-3 {
  height: .75rem;
}

.app-root .h-3\.5 {
  height: .875rem;
}

.app-root .h-4 {
  height: 1rem;
}

.app-root .h-5 {
  height: 1.25rem;
}

.app-root .h-6 {
  height: 1.5rem;
}

.app-root .h-8 {
  height: 2rem;
}

.app-root .h-9 {
  height: 2.25rem;
}

.app-root .h-\[1\.3em\] {
  height: 1.3em;
}

.app-root .h-\[1\.5em\] {
  height: 1.5em;
}

.app-root .h-\[10px\] {
  height: 10px;
}

.app-root .h-\[1px\] {
  height: 1px;
}

.app-root .h-\[350px\] {
  height: 350px;
}

.app-root .h-\[36px\] {
  height: 36px;
}

.app-root .h-\[3px\] {
  height: 3px;
}

.app-root .h-\[600px\] {
  height: 600px;
}

.app-root .h-\[calc\(100\%_\+_12px\)\] {
  height: calc(100% + 12px);
}

.app-root .h-auto {
  height: auto;
}

.app-root .h-full {
  height: 100%;
}

.app-root .h-px {
  height: 1px;
}

.app-root .max-h-\[288px\] {
  max-height: 288px;
}

.app-root .max-h-\[500px\] {
  max-height: 500px;
}

.app-root .min-h-\[50\%\] {
  min-height: 50%;
}

.app-root .min-h-\[60px\] {
  min-height: 60px;
}

.app-root .min-h-\[80px\] {
  min-height: 80px;
}

.app-root .w-0 {
  width: 0;
}

.app-root .w-0\.5 {
  width: .125rem;
}

.app-root .w-1 {
  width: .25rem;
}

.app-root .w-10 {
  width: 2.5rem;
}

.app-root .w-3 {
  width: .75rem;
}

.app-root .w-3\.5 {
  width: .875rem;
}

.app-root .w-4 {
  width: 1rem;
}

.app-root .w-5 {
  width: 1.25rem;
}

.app-root .w-6 {
  width: 1.5rem;
}

.app-root .w-7 {
  width: 1.75rem;
}

.app-root .w-72 {
  width: 18rem;
}

.app-root .w-8 {
  width: 2rem;
}

.app-root .w-9 {
  width: 2.25rem;
}

.app-root .w-\[10px\] {
  width: 10px;
}

.app-root .w-\[160px\] {
  width: 160px;
}

.app-root .w-\[180px\] {
  width: 180px;
}

.app-root .w-\[1px\] {
  width: 1px;
}

.app-root .w-\[200px\] {
  width: 200px;
}

.app-root .w-\[300px\] {
  width: 300px;
}

.app-root .w-\[310px\] {
  width: 310px;
}

.app-root .w-\[316px\] {
  width: 316px;
}

.app-root .w-\[330px\] {
  width: 330px;
}

.app-root .w-\[36px\] {
  width: 36px;
}

.app-root .w-\[418px\] {
  width: 418px;
}

.app-root .w-\[90px\] {
  width: 90px;
}

.app-root .w-auto {
  width: auto;
}

.app-root .w-full {
  width: 100%;
}

.app-root .min-w-0 {
  min-width: 0;
}

.app-root .min-w-\[180px\] {
  min-width: 180px;
}

.app-root .min-w-\[8rem\] {
  min-width: 8rem;
}

.app-root .min-w-\[auto\] {
  min-width: auto;
}

.app-root .min-w-full {
  min-width: 100%;
}

.app-root .max-w-full {
  max-width: 100%;
}

.app-root .max-w-lg {
  max-width: 32rem;
}

.app-root .flex-1 {
  flex: 1;
}

.app-root .flex-shrink, .app-root .shrink {
  flex-shrink: 1;
}

.app-root .shrink-0 {
  flex-shrink: 0;
}

.app-root .flex-grow, .app-root .grow {
  flex-grow: 1;
}

.app-root .table-fixed {
  table-layout: fixed;
}

.app-root .border-collapse {
  border-collapse: collapse;
}

.app-root .-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.app-root .-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.app-root .translate-x-\[-50\%\] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.app-root .translate-y-\[-50\%\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.app-root .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.app-root .cursor-col-resize {
  cursor: col-resize;
}

.app-root .cursor-default {
  cursor: default;
}

.app-root .cursor-not-allowed {
  cursor: not-allowed;
}

.app-root .cursor-pointer {
  cursor: pointer;
}

.app-root .cursor-row-resize {
  cursor: row-resize;
}

.app-root .cursor-text {
  cursor: text;
}

.app-root .select-none {
  -webkit-user-select: none;
  user-select: none;
}

.app-root .resize-none {
  resize: none;
}

.app-root .resize {
  resize: both;
}

.app-root .list-decimal {
  list-style-type: decimal;
}

.app-root .list-disc {
  list-style-type: disc;
}

.app-root .appearance-none {
  appearance: none;
}

.app-root .grid-cols-\[repeat\(10\,1fr\)\] {
  grid-template-columns: repeat(10, 1fr);
}

.app-root .flex-row {
  flex-direction: row;
}

.app-root .flex-col {
  flex-direction: column;
}

.app-root .flex-col-reverse {
  flex-direction: column-reverse;
}

.app-root .flex-wrap {
  flex-wrap: wrap;
}

.app-root .items-end {
  align-items: flex-end;
}

.app-root .items-center {
  align-items: center;
}

.app-root .items-stretch {
  align-items: stretch;
}

.app-root .justify-start {
  justify-content: flex-start;
}

.app-root .justify-center {
  justify-content: center;
}

.app-root .justify-between {
  justify-content: space-between;
}

.app-root .gap-0 {
  gap: 0;
}

.app-root .gap-0\.5 {
  gap: .125rem;
}

.app-root .gap-1 {
  gap: .25rem;
}

.app-root .gap-2 {
  gap: .5rem;
}

.app-root .gap-4 {
  gap: 1rem;
}

.app-root :is(.space-x-1 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.app-root :is(.space-x-2 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.app-root :is(.space-y-1 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.app-root :is(.space-y-1\.5 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
}

.app-root .overflow-hidden {
  overflow: hidden;
}

.app-root .overflow-visible {
  overflow: visible;
}

.app-root .overflow-scroll {
  overflow: scroll;
}

.app-root .overflow-x-auto {
  overflow-x: auto;
}

.app-root .overflow-y-auto {
  overflow-y: auto;
}

.app-root .overflow-x-hidden {
  overflow-x: hidden;
}

.app-root .truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.app-root .whitespace-nowrap {
  white-space: nowrap;
}

.app-root .whitespace-pre-wrap {
  white-space: pre-wrap;
}

.app-root .break-words {
  overflow-wrap: break-word;
}

.app-root .rounded {
  border-radius: .25rem;
}

.app-root .rounded-full {
  border-radius: 9999px;
}

.app-root .rounded-lg {
  border-radius: .5rem;
  border-radius: var(--radius);
}

.app-root .rounded-md {
  border-radius: calc(.5rem - 2px);
  border-radius: calc(var(--radius)  - 2px);
}

.app-root .rounded-sm {
  border-radius: calc(.5rem - 4px);
  border-radius: calc(var(--radius)  - 4px);
}

.app-root .rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.app-root .border {
  border-width: 1px;
}

.app-root .border-0 {
  border-width: 0;
}

.app-root .border-b {
  border-bottom-width: 1px;
}

.app-root .border-b-2 {
  border-bottom-width: 2px;
}

.app-root .border-l-2 {
  border-left-width: 2px;
}

.app-root .border-t {
  border-top-width: 1px;
}

.app-root .border-solid {
  border-style: solid;
}

.app-root .border-none {
  border-style: none;
}

.app-root .border-border {
  border-color: #e2e8f0;
  border-color: hsl(var(--border));
}

.app-root .border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.app-root .border-input {
  border-color: #e2e8f0;
  border-color: hsl(var(--input));
}

.app-root .border-muted {
  border-color: #f1f5f9;
  border-color: hsl(var(--muted));
}

.app-root .border-transparent {
  border-color: rgba(0, 0, 0, 0);
}

.app-root .border-b-gray-100 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.app-root .border-b-primary\/40 {
  border-bottom-color: rgba(15, 23, 42, .4);
  border-bottom-color: hsl(var(--primary) / .4);
}

.app-root .bg-\[--cellBackground\] {
  background-color: var(--cellBackground);
}

.app-root .bg-\[rgba\(0\,0\,0\,0\.05\)\] {
  background-color: rgba(0, 0, 0, .05);
}

.app-root .bg-background {
  background-color: #fff;
  background-color: hsl(var(--background));
}

.app-root .bg-background\/80 {
  background-color: rgba(255, 255, 255, .8);
  background-color: hsl(var(--background) / .8);
}

.app-root .bg-background\/90 {
  background-color: rgba(255, 255, 255, .9);
  background-color: hsl(var(--background) / .9);
}

.app-root .bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.app-root .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.app-root .bg-border {
  background-color: #e2e8f0;
  background-color: hsl(var(--border));
}

.app-root .bg-destructive {
  background-color: #ef4444;
  background-color: hsl(var(--destructive));
}

.app-root .bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.app-root .bg-inherit {
  background-color: inherit;
}

.app-root .bg-muted {
  background-color: #f1f5f9;
  background-color: hsl(var(--muted));
}

.app-root .bg-popover {
  background-color: #fff;
  background-color: hsl(var(--popover));
}

.app-root .bg-primary {
  background-color: #0f172a;
  background-color: hsl(var(--primary));
}

.app-root .bg-primary-focus {
  background-color: rgba(55, 113, 200, .3);
}

.app-root .bg-primary\/20 {
  background-color: rgba(15, 23, 42, .2);
  background-color: hsl(var(--primary) / .2);
}

.app-root .bg-primary\/40 {
  background-color: rgba(15, 23, 42, .4);
  background-color: hsl(var(--primary) / .4);
}

.app-root .bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}

.app-root .bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}

.app-root .bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}

.app-root .bg-ring {
  background-color: #94a3b8;
  background-color: hsl(var(--ring));
}

.app-root .bg-secondary {
  background-color: #f1f5f9;
  background-color: hsl(var(--secondary));
}

.app-root .bg-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.app-root .bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity));
}

.app-root .bg-cover {
  background-size: cover;
}

.app-root .bg-clip-content {
  background-clip: content-box;
}

.app-root .bg-center {
  background-position: center;
}

.app-root .fill-current {
  fill: currentColor;
}

.app-root .object-cover {
  object-fit: cover;
}

.app-root .p-0 {
  padding: 0;
}

.app-root .p-1 {
  padding: .25rem;
}

.app-root .p-2 {
  padding: .5rem;
}

.app-root .p-3 {
  padding: .75rem;
}

.app-root .p-4 {
  padding: 1rem;
}

.app-root .p-6 {
  padding: 1.5rem;
}

.app-root .p-\[1px\] {
  padding: 1px;
}

.app-root .px-0 {
  padding-left: 0;
  padding-right: 0;
}

.app-root .px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.app-root .px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.app-root .px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.app-root .px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.app-root .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.app-root .px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.app-root .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.app-root .px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.app-root .px-\[0\.3em\] {
  padding-left: .3em;
  padding-right: .3em;
}

.app-root .py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.app-root .py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.app-root .py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.app-root .py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.app-root .py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.app-root .py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.app-root .py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.app-root .py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.app-root .py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.app-root .py-\[0\.2em\] {
  padding-top: .2em;
  padding-bottom: .2em;
}

.app-root .pb-0 {
  padding-bottom: 0;
}

.app-root .pb-1 {
  padding-bottom: .25rem;
}

.app-root .pb-4 {
  padding-bottom: 1rem;
}

.app-root .pb-\[51\.25\%\] {
  padding-bottom: 51.25%;
}

.app-root .pb-\[56\.0417\%\] {
  padding-bottom: 56.0417%;
}

.app-root .pb-\[56\.25\%\] {
  padding-bottom: 56.25%;
}

.app-root .pb-\[75\%\] {
  padding-bottom: 75%;
}

.app-root .pl-0 {
  padding-left: 0;
}

.app-root .pl-2 {
  padding-left: .5rem;
}

.app-root .pl-3 {
  padding-left: .75rem;
}

.app-root .pl-6 {
  padding-left: 1.5rem;
}

.app-root .pl-7 {
  padding-left: 1.75rem;
}

.app-root .pl-8 {
  padding-left: 2rem;
}

.app-root .pr-1 {
  padding-right: .25rem;
}

.app-root .pr-2 {
  padding-right: .5rem;
}

.app-root .pr-3 {
  padding-right: .75rem;
}

.app-root .ps-6 {
  padding-inline-start: 1.5rem;
}

.app-root .pt-0 {
  padding-top: 0;
}

.app-root .pt-0\.5 {
  padding-top: .125rem;
}

.app-root .pt-8 {
  padding-top: 2rem;
}

.app-root .pt-\[2px\] {
  padding-top: 2px;
}

.app-root .pt-\[3px\] {
  padding-top: 3px;
}

.app-root .text-left {
  text-align: left;
}

.app-root .text-center {
  text-align: center;
}

.app-root .align-baseline {
  vertical-align: baseline;
}

.app-root .font-\[inherit\] {
  font-family: inherit;
}

.app-root .font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.app-root .text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.app-root .text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.app-root .text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.app-root .text-\[1\.1em\] {
  font-size: 1.1em;
}

.app-root .text-\[1\.25em\] {
  font-size: 1.25em;
}

.app-root .text-\[1\.5em\] {
  font-size: 1.5em;
}

.app-root .text-\[1\.875em\] {
  font-size: 1.875em;
}

.app-root .text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.app-root .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.app-root .text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.app-root .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.app-root .text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.app-root .font-bold {
  font-weight: 700;
}

.app-root .font-medium {
  font-weight: 500;
}

.app-root .font-semibold {
  font-weight: 600;
}

.app-root .uppercase {
  text-transform: uppercase;
}

.app-root .lowercase {
  text-transform: lowercase;
}

.app-root .capitalize {
  text-transform: capitalize;
}

.app-root .italic {
  font-style: italic;
}

.app-root .leading-\[normal\] {
  line-height: normal;
}

.app-root .leading-none {
  line-height: 1;
}

.app-root .tracking-tight {
  letter-spacing: -.025em;
}

.app-root .tracking-widest {
  letter-spacing: .1em;
}

.app-root .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.app-root .text-destructive-foreground {
  color: hsl(var(--destructive-foreground));
}

.app-root .text-foreground {
  color: hsl(var(--foreground));
}

.app-root .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.app-root .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.app-root .text-inherit {
  color: inherit;
}

.app-root .text-muted {
  color: hsl(var(--muted));
}

.app-root .text-muted-foreground {
  color: hsl(var(--muted-foreground));
}

.app-root .text-popover-foreground {
  color: hsl(var(--popover-foreground));
}

.app-root .text-primary {
  color: hsl(var(--primary));
}

.app-root .text-primary-foreground {
  color: hsl(var(--primary-foreground));
}

.app-root .text-secondary-foreground {
  color: hsl(var(--secondary-foreground));
}

.app-root .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.app-root .underline {
  text-decoration-line: underline;
}

.app-root .overline {
  text-decoration-line: overline;
}

.app-root .line-through {
  text-decoration-line: line-through;
}

.app-root .decoration-primary {
  text-decoration-color: #0f172a;
  -webkit-text-decoration-color: hsl(var(--primary));
  -webkit-text-decoration-color: hsl(var(--primary));
  text-decoration-color: hsl(var(--primary));
}

.app-root .underline-offset-4 {
  text-underline-offset: 4px;
}

.app-root .opacity-0 {
  opacity: 0;
}

.app-root .opacity-100 {
  opacity: 1;
}

.app-root .opacity-50 {
  opacity: .5;
}

.app-root .opacity-60 {
  opacity: .6;
}

.app-root .opacity-70 {
  opacity: .7;
}

.app-root .opacity-\[0\.3\] {
  opacity: .3;
}

.app-root .shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.app-root .shadow-\[rgb\(15_15_15_\/_5\%\)_0_0_0_1px\,_rgb\(15_15_15_\/_10\%\)_0_3px_6px\,_rgb\(15_15_15_\/_20\%\)_0_9px_24px\] {
  --tw-shadow: rgba(15, 15, 15, .05) 0 0 0 1px, rgba(15, 15, 15, .1) 0 3px 6px, rgba(15, 15, 15, .2) 0 9px 24px;
  --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color), 0 3px 6px var(--tw-shadow-color), 0 9px 24px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.app-root .shadow-\[rgba\(255\,_255\,_255\,_0\.1\)_0px_0\.5px_0px_0px_inset\,_rgb\(248\,_249\,_250\)_0px_1px_5px_0px_inset\,_rgb\(193\,_200\,_205\)_0px_0px_0px_0\.5px\,_rgb\(193\,_200\,_205\)_0px_2px_1px_-1px\,_rgb\(193\,_200\,_205\)_0px_1px_0px_0px\] {
  --tw-shadow: rgba(255, 255, 255, .1) 0px .5px 0px 0px inset, #f8f9fa 0px 1px 5px 0px inset, #c1c8cd 0px 0px 0px .5px, #c1c8cd 0px 2px 1px -1px, #c1c8cd 0px 1px 0px 0px;
  --tw-shadow-colored: inset 0px .5px 0px 0px var(--tw-shadow-color), inset 0px 1px 5px 0px var(--tw-shadow-color), 0px 0px 0px .5px var(--tw-shadow-color), 0px 2px 1px -1px var(--tw-shadow-color), 0px 1px 0px 0px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.app-root .shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.app-root .shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.app-root .outline-none {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.app-root .\!outline {
  outline-style: solid !important;
}

.app-root .outline {
  outline-style: solid;
}

.app-root .ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 rgba(0, 0, 0, 0);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.app-root .ring-ring {
  --tw-ring-color: hsl(var(--ring));
}

.app-root .ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.app-root .ring-offset-background {
  --tw-ring-offset-color: hsl(var(--background));
}

.app-root .blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.app-root .invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.app-root .sepia {
  --tw-sepia: sepia(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.app-root .filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.app-root .backdrop-blur-\[4px\], .app-root .backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.app-root .backdrop-filter {
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.app-root .transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.app-root .transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.app-root .transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.app-root .transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.app-root .duration-200 {
  transition-duration: .2s;
}

.app-root .ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@keyframes enter {
  from {
    opacity: 1;
    opacity: var(--tw-enter-opacity, 1);
    transform: translate3d(0, 0, 0)scale3d(1, 1, 1)rotate(0);
    transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
  }
}

@keyframes exit {
  to {
    opacity: 1;
    opacity: var(--tw-exit-opacity, 1);
    transform: translate3d(0, 0, 0)scale3d(1, 1, 1)rotate(0);
    transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
  }
}

.app-root .duration-200 {
  animation-duration: .2s;
}

.app-root .ease-in-out {
  animation-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.app-root .running {
  animation-play-state: running;
}

.\[contain\:content\] {
  contain: content;
}

.\[tab-size\:2\] {
  tab-size: 2;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-BoldItalic.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-BoldItalic.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-ThinItalic.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-ThinItalic.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-MediumItalic.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-MediumItalic.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-BlackItalic.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-BlackItalic.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-RegularItalic.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-RegularItalic.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-Black.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-Black.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-Bold.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-Bold.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-Regular.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-Regular.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-LightItalic.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-LightItalic.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-Light.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-Light.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-Medium.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-Medium.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: url("../assets/fonts/metropolis/Metropolis-Thin.woff2") format("woff2"), url("../assets/fonts/metropolis/Metropolis-Thin.woff") format("woff"), url("../assets/fonts/metropolis/Metropolis-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-Light.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-Light.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-Bold.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-Bold.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-MediumItalic.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-MediumItalic.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-Regular.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-Regular.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-SemiBold.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-SemiBold.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-SemiBoldItalic.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-SemiBoldItalic.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-ExtraBold.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-ExtraBold.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-Black.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-Black.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-BlackItalic.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-BlackItalic.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-Medium.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-Medium.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-LightItalic.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-LightItalic.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-Italic.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-Italic.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-ExtraBoldItalic.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-ExtraBoldItalic.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-ExtraBoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Red Hat Display;
  src: url("../assets/fonts/red-hat-display/RedHatDisplay-BoldItalic.woff2") format("woff2"), url("../assets/fonts/red-hat-display/RedHatDisplay-BoldItalic.woff") format("woff"), url("../assets/fonts/red-hat-display/RedHatDisplay-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

.app-root .file\:border-0::file-selector-button {
  border-width: 0;
}

.app-root .file\:bg-background::file-selector-button {
  background-color: #fff;
  background-color: hsl(var(--background));
}

.app-root .file\:text-sm::file-selector-button {
  font-size: .875rem;
  line-height: 1.25rem;
}

.app-root .file\:font-medium::file-selector-button {
  font-weight: 500;
}

.app-root .placeholder\:text-muted-foreground::placeholder {
  color: hsl(var(--muted-foreground));
}

.app-root .before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.app-root .before\:z-10:before {
  content: var(--tw-content);
  z-index: 10;
}

.app-root .before\:box-border:before {
  content: var(--tw-content);
  box-sizing: border-box;
}

.app-root .before\:h-full:before {
  content: var(--tw-content);
  height: 100%;
}

.app-root .before\:w-full:before {
  content: var(--tw-content);
  width: 100%;
}

.app-root .before\:cursor-text:before {
  content: var(--tw-content);
  cursor: text;
}

.app-root .before\:select-none:before {
  content: var(--tw-content);
  -webkit-user-select: none;
  user-select: none;
}

.app-root .before\:border-b:before {
  content: var(--tw-content);
  border-bottom-width: 1px;
}

.app-root .before\:border-l:before {
  content: var(--tw-content);
  border-left-width: 1px;
}

.app-root .before\:border-r:before {
  content: var(--tw-content);
  border-right-width: 1px;
}

.app-root .before\:border-t:before {
  content: var(--tw-content);
  border-top-width: 1px;
}

.app-root .before\:border-none:before {
  content: var(--tw-content);
  border-style: none;
}

.app-root .before\:border-b-border:before {
  content: var(--tw-content);
  border-bottom-color: #e2e8f0;
  border-bottom-color: hsl(var(--border));
}

.app-root .before\:border-l-border:before {
  content: var(--tw-content);
  border-left-color: #e2e8f0;
  border-left-color: hsl(var(--border));
}

.app-root .before\:border-r-border:before {
  content: var(--tw-content);
  border-right-color: #e2e8f0;
  border-right-color: hsl(var(--border));
}

.app-root .before\:border-t-border:before {
  content: var(--tw-content);
  border-top-color: #e2e8f0;
  border-top-color: hsl(var(--border));
}

.app-root .before\:bg-muted:before {
  content: var(--tw-content);
  background-color: #f1f5f9;
  background-color: hsl(var(--muted));
}

.app-root .before\:opacity-30:before {
  content: var(--tw-content);
  opacity: .3;
}

.app-root .before\:content-\[\'\'\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.app-root .before\:content-\[attr\(placeholder\)\]:before {
  --tw-content: attr(placeholder);
  content: var(--tw-content);
}

.app-root .after\:block:after {
  content: var(--tw-content);
  display: block;
}

.app-root .after\:flex:after {
  content: var(--tw-content);
  display: flex;
}

.app-root .after\:h-16:after {
  content: var(--tw-content);
  height: 4rem;
}

.app-root .after\:w-\[3px\]:after {
  content: var(--tw-content);
  width: 3px;
}

.app-root .after\:rounded-\[6px\]:after {
  content: var(--tw-content);
  border-radius: 6px;
}

.app-root .after\:bg-ring:after {
  content: var(--tw-content);
  background-color: #94a3b8;
  background-color: hsl(var(--ring));
}

.app-root .after\:pb-\[var\(--aspect-ratio\)\]:after {
  content: var(--tw-content);
  padding-bottom: var(--aspect-ratio);
}

.app-root .after\:opacity-0:after {
  content: var(--tw-content);
  opacity: 0;
}

.app-root .after\:content-\[\"\"\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.app-root .after\:content-\[\'_\'\]:after {
  --tw-content: " ";
  content: var(--tw-content);
}

.app-root .hover\:bg-accent:hover {
  background-color: #f1f5f9;
  background-color: hsl(var(--accent));
}

.app-root .hover\:bg-destructive\/90:hover {
  background-color: rgba(239, 68, 68, .9);
  background-color: hsl(var(--destructive) / .9);
}

.app-root .hover\:bg-muted:hover {
  background-color: #f1f5f9;
  background-color: hsl(var(--muted));
}

.app-root .hover\:bg-primary\/90:hover {
  background-color: rgba(15, 23, 42, .9);
  background-color: hsl(var(--primary) / .9);
}

.app-root .hover\:bg-secondary\/80:hover {
  background-color: rgba(241, 245, 249, .8);
  background-color: hsl(var(--secondary) / .8);
}

.app-root .hover\:text-accent-foreground:hover {
  color: hsl(var(--accent-foreground));
}

.app-root .hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.app-root .hover\:text-muted-foreground:hover {
  color: hsl(var(--muted-foreground));
}

.app-root .hover\:underline:hover {
  text-decoration-line: underline;
}

.app-root .hover\:opacity-100:hover {
  opacity: 1;
}

.app-root .hover\:before\:absolute:hover:before {
  content: var(--tw-content);
  position: absolute;
}

.app-root .hover\:before\:cursor-text:hover:before {
  content: var(--tw-content);
  cursor: text;
}

.app-root .hover\:before\:opacity-30:hover:before {
  content: var(--tw-content);
  opacity: .3;
}

.app-root .hover\:before\:content-\[attr\(placeholder\)\]:hover:before {
  --tw-content: attr(placeholder);
  content: var(--tw-content);
}

.app-root .focus\:bg-accent:focus {
  background-color: #f1f5f9;
  background-color: hsl(var(--accent));
}

.app-root .focus\:text-accent-foreground:focus {
  color: hsl(var(--accent-foreground));
}

.app-root .focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.app-root .focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 rgba(0, 0, 0, 0);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.app-root .focus\:ring-ring:focus {
  --tw-ring-color: hsl(var(--ring));
}

.app-root .focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.app-root .focus-visible\:outline-none:focus-visible {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.app-root .focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 rgba(0, 0, 0, 0);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.app-root .focus-visible\:ring-ring:focus-visible {
  --tw-ring-color: hsl(var(--ring));
}

.app-root .focus-visible\:ring-transparent:focus-visible {
  --tw-ring-color: transparent;
}

.app-root .focus-visible\:ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px;
}

.app-root .disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.app-root .disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.app-root .disabled\:opacity-50:disabled {
  opacity: .5;
}

.app-root :is(.group:hover .group-hover\:opacity-100) {
  opacity: 1;
}

.app-root :is(.group:hover .group-hover\:after\:opacity-100):after {
  content: var(--tw-content);
  opacity: 1;
}

.app-root .aria-selected\:bg-accent[aria-selected="true"] {
  background-color: #f1f5f9;
  background-color: hsl(var(--accent));
}

.app-root .aria-selected\:text-accent-foreground[aria-selected="true"] {
  color: hsl(var(--accent-foreground));
}

.app-root .data-\[disabled\]\:pointer-events-none[data-disabled] {
  pointer-events: none;
}

.app-root .data-\[highlighted\=true\]\:bg-accent[data-highlighted="true"], .app-root .data-\[state\=checked\]\:bg-accent[data-state="checked"], .app-root .data-\[state\=on\]\:bg-accent[data-state="on"], .app-root .data-\[state\=open\]\:bg-accent[data-state="open"] {
  background-color: #f1f5f9;
  background-color: hsl(var(--accent));
}

.app-root .data-\[highlighted\=true\]\:text-accent-foreground[data-highlighted="true"], .app-root .data-\[state\=checked\]\:text-accent-foreground[data-state="checked"], .app-root .data-\[state\=on\]\:text-accent-foreground[data-state="on"] {
  color: hsl(var(--accent-foreground));
}

.app-root .data-\[state\=open\]\:text-muted-foreground[data-state="open"] {
  color: hsl(var(--muted-foreground));
}

.app-root .data-\[disabled\]\:opacity-50[data-disabled] {
  opacity: .5;
}

.app-root .data-\[state\=open\]\:animate-in[data-state="open"] {
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
  animation-name: enter;
  animation-duration: .15s;
}

.app-root .data-\[state\=closed\]\:animate-out[data-state="closed"] {
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
  animation-name: exit;
  animation-duration: .15s;
}

.app-root .data-\[state\=closed\]\:fade-out-0[data-state="closed"] {
  --tw-exit-opacity: 0;
}

.app-root .data-\[state\=open\]\:fade-in-0[data-state="open"] {
  --tw-enter-opacity: 0;
}

.app-root .data-\[state\=closed\]\:zoom-out-95[data-state="closed"] {
  --tw-exit-scale: .95;
}

.app-root .data-\[state\=open\]\:zoom-in-95[data-state="open"] {
  --tw-enter-scale: .95;
}

.app-root .data-\[side\=bottom\]\:slide-in-from-top-2[data-side="bottom"] {
  --tw-enter-translate-y: -.5rem;
}

.app-root .data-\[side\=left\]\:slide-in-from-right-2[data-side="left"] {
  --tw-enter-translate-x: .5rem;
}

.app-root .data-\[side\=right\]\:slide-in-from-left-2[data-side="right"] {
  --tw-enter-translate-x: -.5rem;
}

.app-root .data-\[side\=top\]\:slide-in-from-bottom-2[data-side="top"] {
  --tw-enter-translate-y: .5rem;
}

.app-root .data-\[state\=closed\]\:slide-out-to-left-1\/2[data-state="closed"] {
  --tw-exit-translate-x: -50%;
}

.app-root .data-\[state\=closed\]\:slide-out-to-top-\[48\%\][data-state="closed"] {
  --tw-exit-translate-y: -48%;
}

.app-root .data-\[state\=open\]\:slide-in-from-left-1\/2[data-state="open"] {
  --tw-enter-translate-x: -50%;
}

.app-root .data-\[state\=open\]\:slide-in-from-top-\[48\%\][data-state="open"] {
  --tw-enter-translate-y: -48%;
}

.app-root :is(.dark .dark\:shadow-\[rgba\(255\,_255\,_255\,_0\.1\)_0px_0\.5px_0px_0px_inset\,_rgb\(26\,_29\,_30\)_0px_1px_5px_0px_inset\,_rgb\(76\,_81\,_85\)_0px_0px_0px_0\.5px\,_rgb\(76\,_81\,_85\)_0px_2px_1px_-1px\,_rgb\(76\,_81\,_85\)_0px_1px_0px_0px\]) {
  --tw-shadow: rgba(255, 255, 255, .1) 0px .5px 0px 0px inset, #1a1d1e 0px 1px 5px 0px inset, #4c5155 0px 0px 0px .5px, #4c5155 0px 2px 1px -1px, #4c5155 0px 1px 0px 0px;
  --tw-shadow-colored: inset 0px .5px 0px 0px var(--tw-shadow-color), inset 0px 1px 5px 0px var(--tw-shadow-color), 0px 0px 0px .5px var(--tw-shadow-color), 0px 2px 1px -1px var(--tw-shadow-color), 0px 1px 0px 0px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

@media print {
  .app-root .print\:hidden {
    display: none;
  }

  .app-root .print\:placeholder\:text-transparent::placeholder {
    color: rgba(0, 0, 0, 0);
  }
}

@media not all and (min-width: 1024px) {
  .app-root .max-lg\:hidden {
    display: none;
  }
}

@media (min-width: 640px) {
  .app-root .sm\:flex-row {
    flex-direction: row;
  }

  .app-root .sm\:justify-end {
    justify-content: flex-end;
  }

  .app-root :is(.sm\:space-x-2 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .app-root .sm\:rounded-lg {
    border-radius: .5rem;
    border-radius: var(--radius);
  }

  .app-root .sm\:text-left {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .app-root .md\:w-full {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .app-root .lg\:inline {
    display: inline;
  }

  .app-root .lg\:hidden {
    display: none;
  }

  .app-root .lg\:min-w-\[130px\] {
    min-width: 130px;
  }
}

.app-root .\[\&\.lyt-activated\]\:cursor-\[unset\].lyt-activated {
  cursor: inherit;
}

.app-root .\[\&\.lyt-activated\]\:before\:pointer-events-none.lyt-activated:before {
  content: var(--tw-content);
  pointer-events: none;
}

.app-root .\[\&\.lyt-activated\]\:before\:absolute.lyt-activated:before {
  content: var(--tw-content);
  position: absolute;
}

.app-root .\[\&\.lyt-activated\]\:before\:top-0.lyt-activated:before {
  content: var(--tw-content);
  top: 0;
}

.app-root .\[\&\.lyt-activated\]\:before\:h-\[60px\].lyt-activated:before {
  content: var(--tw-content);
  height: 60px;
}

.app-root .\[\&\.lyt-activated\]\:before\:w-full.lyt-activated:before {
  content: var(--tw-content);
  width: 100%;
}

.app-root .\[\&\.lyt-activated\]\:before\:bg-\[url\(data\:image\/png\;base64\,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT\+OqFAAAAdklEQVQoz42QQQ7AIAgEF\/T\/D\+kbq\/RWAlnQyyazA4aoAB4FsBSA\/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg\=\=\)\].lyt-activated:before {
  content: var(--tw-content);
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==");
}

.app-root .\[\&\.lyt-activated\]\:before\:bg-top.lyt-activated:before {
  content: var(--tw-content);
  background-position: top;
}

.app-root .\[\&\.lyt-activated\]\:before\:bg-repeat-x.lyt-activated:before {
  content: var(--tw-content);
  background-repeat: repeat-x;
}

.app-root .\[\&\.lyt-activated\]\:before\:pb-\[50px\].lyt-activated:before {
  content: var(--tw-content);
  padding-bottom: 50px;
}

.app-root .\[\&\.lyt-activated\]\:before\:opacity-0.lyt-activated:before {
  content: var(--tw-content);
  opacity: 0;
}

.\[\&\.lyt-activated\]\:before\:\[transition\:all_0\.2s_cubic-bezier\(0\,_0\,_0\.2\,_1\)\].lyt-activated:before {
  content: var(--tw-content);
  transition: all .2s cubic-bezier(0, 0, .2, 1);
}

.app-root :is(.\[\&\.lyt-activated_\>_\.lty-playbtn\]\:pointer-events-none.lyt-activated > .lty-playbtn) {
  pointer-events: none;
}

.app-root :is(.\[\&\.lyt-activated_\>_\.lty-playbtn\]\:\!opacity-0.lyt-activated > .lty-playbtn) {
  opacity: 0 !important;
}

.app-root .\[\&\:\:-webkit-scrollbar-button\]\:hidden::-webkit-scrollbar-button {
  display: none;
}

.app-root .\[\&\:\:-webkit-scrollbar-button\]\:h-0::-webkit-scrollbar-button {
  height: 0;
}

.app-root .\[\&\:\:-webkit-scrollbar-button\]\:w-0::-webkit-scrollbar-button {
  width: 0;
}

.app-root .\[\&\:\:-webkit-scrollbar-thumb\]\:min-h-\[65px\]::-webkit-scrollbar-thumb {
  min-height: 65px;
}

.app-root .\[\&\:\:-webkit-scrollbar-thumb\]\:rounded-2xl::-webkit-scrollbar-thumb {
  border-radius: 1rem;
}

.app-root .\[\&\:\:-webkit-scrollbar-thumb\]\:border-4::-webkit-scrollbar-thumb {
  border-width: 4px;
}

.app-root .\[\&\:\:-webkit-scrollbar-thumb\]\:border-white::-webkit-scrollbar-thumb {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.app-root .\[\&\:\:-webkit-scrollbar-track\]\:border-0::-webkit-scrollbar-track {
  border-width: 0;
}

.app-root .\[\&\:\:-webkit-scrollbar\]\:w-4::-webkit-scrollbar {
  width: 1rem;
}

.app-root .focus\:\[\&\:\:placeholder\]\:opacity-0:focus::placeholder {
  opacity: 0;
}

.app-root :is(.\[\&\:hover_\>_\.lty-playbtn\]\:bg-\[red\]:hover > .lty-playbtn) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 0 / var(--tw-bg-opacity));
}

.app-root :is(.\[\&\:hover_\>_\.lty-playbtn\]\:opacity-100:hover > .lty-playbtn) {
  opacity: 1;
}

.app-root :is(.\[\&_\*\:\:selection\]\:bg-none *)::selection {
  background-image: none;
}

.app-root :is(.\[\&_\.react-tweet-theme\]\:my-0 .react-tweet-theme) {
  margin-top: 0;
  margin-bottom: 0;
}

.app-root :is(.\[\&_\.react-tweet-theme\]\:ring-2 .react-tweet-theme) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 rgba(0, 0, 0, 0);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.app-root :is(.\[\&_\.react-tweet-theme\]\:ring-ring .react-tweet-theme) {
  --tw-ring-color: hsl(var(--ring));
}

.app-root :is(.\[\&_\.react-tweet-theme\]\:ring-offset-2 .react-tweet-theme) {
  --tw-ring-offset-width: 2px;
}

.app-root :is(.\[\&_\>_\*\]\:m-0 > *) {
  margin: 0;
}

.app-root :is(.\[\&_\>_\.lty-playbtn\]\:absolute > .lty-playbtn) {
  position: absolute;
}

.app-root :is(.\[\&_\>_\.lty-playbtn\]\:left-1\/2 > .lty-playbtn) {
  left: 50%;
}

.app-root :is(.\[\&_\>_\.lty-playbtn\]\:top-1\/2 > .lty-playbtn) {
  top: 50%;
}

.app-root :is(.\[\&_\>_\.lty-playbtn\]\:z-\[1\] > .lty-playbtn) {
  z-index: 1;
}

.app-root :is(.\[\&_\>_\.lty-playbtn\]\:h-\[46px\] > .lty-playbtn) {
  height: 46px;
}

.app-root :is(.\[\&_\>_\.lty-playbtn\]\:w-\[70px\] > .lty-playbtn) {
  width: 70px;
}

.app-root :is(.\[\&_\>_\.lty-playbtn\]\:rounded-\[14\%\] > .lty-playbtn) {
  border-radius: 14%;
}

.app-root :is(.\[\&_\>_\.lty-playbtn\]\:bg-\[\#212121\] > .lty-playbtn) {
  --tw-bg-opacity: 1;
  background-color: rgb(33 33 33 / var(--tw-bg-opacity));
}

.app-root :is(.\[\&_\>_\.lty-playbtn\]\:opacity-80 > .lty-playbtn) {
  opacity: .8;
}

.\[\&_\>_\.lty-playbtn\]\:\[transform\:translate3d\(-50\%\,-50\%\,0\)\] > .lty-playbtn {
  transform: translate3d(-50%, -50%, 0);
}

.\[\&_\>_\.lty-playbtn\]\:\[transition\:all_0\.2s_cubic-bezier\(0\,_0\,_0\.2\,_1\)\] > .lty-playbtn {
  transition: all .2s cubic-bezier(0, 0, .2, 1);
}

.app-root :is(.\[\&_\>_\.lty-playbtn\]\:before\:absolute > .lty-playbtn):before {
  content: var(--tw-content);
  position: absolute;
}

.app-root :is(.\[\&_\>_\.lty-playbtn\]\:before\:left-1\/2 > .lty-playbtn):before {
  content: var(--tw-content);
  left: 50%;
}

.app-root :is(.\[\&_\>_\.lty-playbtn\]\:before\:top-1\/2 > .lty-playbtn):before {
  content: var(--tw-content);
  top: 50%;
}

.app-root :is(.\[\&_\>_\.lty-playbtn\]\:before\:border-y-\[11px\] > .lty-playbtn):before {
  content: var(--tw-content);
  border-top-width: 11px;
  border-bottom-width: 11px;
}

.app-root :is(.\[\&_\>_\.lty-playbtn\]\:before\:border-l-\[19px\] > .lty-playbtn):before {
  content: var(--tw-content);
  border-left-width: 19px;
}

.app-root :is(.\[\&_\>_\.lty-playbtn\]\:before\:border-r-0 > .lty-playbtn):before {
  content: var(--tw-content);
  border-right-width: 0;
}

.app-root :is(.\[\&_\>_\.lty-playbtn\]\:before\:border-\[transparent_transparent_transparent_\#fff\] > .lty-playbtn):before {
  content: var(--tw-content);
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff;
}

.app-root :is(.\[\&_\>_\.lty-playbtn\]\:before\:content-\[\"\"\] > .lty-playbtn):before {
  --tw-content: "";
  content: var(--tw-content);
}

.\[\&_\>_\.lty-playbtn\]\:before\:\[transform\:translate3d\(-50\%\,-50\%\,0\)\] > .lty-playbtn:before {
  content: var(--tw-content);
  transform: translate3d(-50%, -50%, 0);
}

.app-root :is(.\[\&_\>_iframe\]\:absolute > iframe) {
  position: absolute;
}

.app-root :is(.\[\&_\>_iframe\]\:left-0 > iframe) {
  left: 0;
}

.app-root :is(.\[\&_\>_iframe\]\:top-0 > iframe) {
  top: 0;
}

.app-root :is(.\[\&_\>_iframe\]\:h-full > iframe) {
  height: 100%;
}

.app-root :is(.\[\&_\>_iframe\]\:w-full > iframe) {
  width: 100%;
}

.app-root :is(.\[\&_\[cmdk-group-heading\]\]\:px-2 [cmdk-group-heading]) {
  padding-left: .5rem;
  padding-right: .5rem;
}

.app-root :is(.\[\&_\[cmdk-group-heading\]\]\:py-1\.5 [cmdk-group-heading]) {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.app-root :is(.\[\&_\[cmdk-group-heading\]\]\:text-xs [cmdk-group-heading]) {
  font-size: .75rem;
  line-height: 1rem;
}

.app-root :is(.\[\&_\[cmdk-group-heading\]\]\:font-medium [cmdk-group-heading]) {
  font-weight: 500;
}

.app-root :is(.\[\&_\[cmdk-group-heading\]\]\:text-muted-foreground [cmdk-group-heading]) {
  color: hsl(var(--muted-foreground));
}

.app-root :is(.\[\&_\[cmdk-group\]\:not\(\[hidden\]\)_\~\[cmdk-group\]\]\:pt-0 [cmdk-group]:not([hidden]) ~ [cmdk-group]) {
  padding-top: 0;
}

.app-root :is(.\[\&_\[cmdk-group\]\]\:px-2 [cmdk-group]) {
  padding-left: .5rem;
  padding-right: .5rem;
}

.app-root :is(.\[\&_\[cmdk-input-wrapper\]_svg\]\:h-5 [cmdk-input-wrapper] svg) {
  height: 1.25rem;
}

.app-root :is(.\[\&_\[cmdk-input-wrapper\]_svg\]\:w-5 [cmdk-input-wrapper] svg) {
  width: 1.25rem;
}

.app-root :is(.\[\&_\[cmdk-input\]\]\:h-12 [cmdk-input]) {
  height: 3rem;
}

.app-root :is(.\[\&_\[cmdk-item\]\]\:px-2 [cmdk-item]) {
  padding-left: .5rem;
  padding-right: .5rem;
}

.app-root :is(.\[\&_\[cmdk-item\]\]\:py-3 [cmdk-item]) {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.app-root :is(.\[\&_\[cmdk-item\]_svg\]\:h-5 [cmdk-item] svg) {
  height: 1.25rem;
}

.app-root :is(.\[\&_\[cmdk-item\]_svg\]\:w-5 [cmdk-item] svg) {
  width: 1.25rem;
}

.app-root :is(.\[\&_\[data-slate-placeholder\]\]\:top-\[auto_\!important\] [data-slate-placeholder]) {
  top: auto !important;
}

.app-root :is(.\[\&_\[data-slate-placeholder\]\]\:text-muted-foreground [data-slate-placeholder]) {
  color: hsl(var(--muted-foreground));
}

.app-root :is(.\[\&_\[data-slate-placeholder\]\]\:\!opacity-100 [data-slate-placeholder]) {
  opacity: 1 !important;
}

.app-root :is(.\[\&_strong\]\:font-bold strong) {
  font-weight: 700;
}

.app-root :is(.\[\&_svg\:not\(\[data-icon\]\)\]\:h-5 svg:not([data-icon])) {
  height: 1.25rem;
}

.app-root :is(.\[\&_svg\:not\(\[data-icon\]\)\]\:w-5 svg:not([data-icon])) {
  width: 1.25rem;
}

.app-root :is(.\[\&_ul\]\:list-\[circle\] ul) {
  list-style-type: circle;
}

.app-root :is(.\[\&_ul_ul\]\:list-\[square\] ul ul) {
  list-style-type: square;
}

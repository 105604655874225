.icon {
  font-size: 1.25rem;
}

.dangle {
  cursor: pointer;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  z-index: 97;
  outline: none;
  width: 4rem;
  height: 4rem;
  transition: all .15s linear;
  position: fixed;
  right: 0;
  overflow: hidden;
  transform: translateX(1rem);
}

.dangle:hover {
  transform: translateX(0);
}

.dangle .icon {
  opacity: .65;
}

.dangle.active {
  z-index: 100;
}

.dangle.active .icon {
  opacity: 1;
  color: var(--primary);
}

.dangle.opened {
  opacity: .8;
  transform: translateX(-15.5rem);
}

.dangle.opened:hover {
  transform: translateX(-16.5rem);
}

.dangle.opened.active {
  opacity: 1;
  transform: translateX(-16.5rem);
}

.arrowContainer {
  pointer-events: auto;
  border-radius: 54px 0 0 54px;
  align-items: center;
  width: 72px;
  height: 44px;
  margin: 8px 0 8px 8px;
  padding: 10px 0 10px 10px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, .14), 0 0 10px rgba(0, 0, 0, .12), 0 0 4px -1px rgba(0, 0, 0, .2);
}

.arrowContainer:hover .icon {
  opacity: 1;
}

.notification {
  background: var(--primary);
  color: #fff;
  border-radius: 50%;
  width: .8rem;
  height: .8rem;
  font-size: 10px;
  font-style: normal;
  position: absolute;
  bottom: 0;
  left: -.25rem;
}

.notification span {
  justify-content: center;
  display: flex;
}

.container {
  width: 100%;
  position: relative;
}

.bar {
  composes: progress from global;
  border-radius: 3px;
}

.container, .bar {
  height: 1.3rem;
}

.value {
  position: absolute;
  inset: 0;
}

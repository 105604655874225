.bubble-chart {
  vertical-align: top;
  text-align: center;
  width: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.bubble-chart__svg {
  -webkit-user-select: none;
  user-select: none;
  max-height: calc(100vh - 16rem);
  margin: 0 auto;
  display: block;
}

.bubble-chart__svg--vertical {
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  max-height: calc(100vh - 5rem);
  display: inline-block;
  position: static;
}

.bubble-chart .bubble-chart__info {
  opacity: 0;
  transition: opacity .5s ease-in-out;
}

.bubble-chart .bubble-chart__info:hover {
  cursor: pointer;
}

.bubble-chart .bubble-chart__info--visible {
  opacity: 1;
  transition: opacity .35s ease-in-out;
}

.bubble-chart .bubble-chart__info--toggleable {
  opacity: .01;
  transition: opacity .35s ease-in-out;
}

.bubble-chart .bubble-chart__info--toggleable:hover {
  opacity: 1;
}

.bubble-chart__bubble-circle {
  transition: fill .35s ease-in-out, stroke .35s ease-in-out, opacity .5s ease-in-out;
}

.bubble-chart__bubble-circle--hidden {
  opacity: 0;
}

.bubble-chart__bubble-circle:hover {
  cursor: pointer;
}

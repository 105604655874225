.wordsList {
  border-top: 1px solid rgba(0, 40, 100, .12);
  flex: auto;
  margin-top: .5rem;
}

.wordsListRow {
  border-bottom: 1px solid rgba(0, 40, 100, .12);
  padding: .75rem 1.25rem;
}

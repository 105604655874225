.dimmer {
  position: relative;
}

.dimmer .loader {
  background-image: url("../../assets/icon-neuroflash-logo-sidebar.svg");
  background-repeat: no-repeat;
  background-size: 100px 100px;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: none;
  position: fixed;
  top: calc(50% - 100px);
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.dimmer .loader :global {
  animation: 1s infinite ld-breath;
}

.dimmer .loader.relative {
  position: relative;
}

.dimmer.active .loader {
  z-index: 1000;
  display: block;
}

.dimmer.active .dimmerContent {
  opacity: .3;
  pointer-events: none;
}

.dimmerContent {
  height: 100%;
}

.dropdownContainer {
  border-bottom: 1px solid rgba(0, 40, 100, .12);
  justify-content: space-between;
  padding-bottom: .25rem;
  display: flex;
}

:global(.lists-dropdown .dropdown-menu) {
  max-width: 15rem;
  max-height: 25rem;
  overflow: scroll;
}

:global(.lists-dropdown .dropdown-item) {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 15rem;
  overflow: hidden;
}

.trigger {
  font-weight: 600;
  display: inline-flex;
}

.triggerText {
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
  display: inline-block;
  overflow: hidden;
}

.triggerText:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid rgba(0, 0, 0, 0);
  border-top-color: currentColor;
  border-bottom: 0;
  width: 0;
  height: 0;
  margin-left: .255em;
  display: inline-block;
}

.flag {
  align-self: center;
  max-height: 1rem;
  padding: 0 .5rem 0 .25rem;
}
